<template>
	<div>
		<div>
			<font-awesome-icon :icon="icon" class="header_icon"></font-awesome-icon>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		components: {
		},
		props: {
			icon: { type: Array },
		},
		data: () => ({
		}),
		created() {

		},
		methods:{
			
		},
		watch: {
			
		}
	}
</script>
